<template>
    <div class="p-t-5">
        <h4 class="workplatform-title">资源站点分布</h4>
        <Row v-for="item in stationCount" :key="item.key">
            <i-col span="24">
              <span class="title">{{item.values[0].assetName}}</span>
                <a class="m-l-5" v-for="station in item.values" :key="station.stationId " @click="handleStationShow(station)">
                    {{station.stationName}}({{station.quantity }})
                </a>
            </i-col>
        </Row>
        <!-- 显示等级分布 -->
        <Row :gutter="8" class="p-b-5">

            <i-col v-for="(item,i) in levelCount" :key="i" span="4">
                <div :class="currentLevel ===item.level?'order-level-active':'order-level'" @click="handleSearchLevel(item)">
                    <span>{{item.levelName}}</span>
                    <span class="right">{{item.quantity}}</span>
                </div>
            </i-col>
        </Row>

        <Row :gutter="8" class="p-b-5">
            <i-col span="4">
              <Button type="error" v-if="!customskuId" size="small" long :disabled="selection.length<1" @click="handleDeleteShow()">批量删除</Button>
            </i-col>
            <i-col span="12">
              <i-input size="small" v-model="query.keyword" placeholder="输入查询关键字：站点、编号等"></i-input>
            </i-col>
            <i-col span="8">
              <Button icon="ios-search" size="small" type="primary" @click="handleSearch">搜索</Button>
            </i-col>
        </Row>

        <Row class="table-title p-t-2">
            <i-col span="2">
                <Checkbox style="margin-top: -3px" size="small" v-model="checkAllResource" @on-change="handlCheckAllResource"></Checkbox>
            </i-col>
            <i-col span="3">编号</i-col>
            <i-col span="2">站台</i-col>
            <i-col span="3">资产</i-col>
            <i-col span="1">级别</i-col>
            <i-col span="2">资源类型</i-col>
            <i-col span="2" class="text-center">楼层</i-col>
            <i-col span="2" class="text-center">出口</i-col>
            <i-col span="2">位置</i-col>
            <i-col span="3">价格</i-col>
            <i-col span="2">操作</i-col>
        </Row>
        <Row v-for="(resource,index) in resourceList" :key="index" class="p-t-2" :class="computedRowClassName(resource,index)">
            <i-col span="2">
                <Checkbox style="margin-top: -3px" size="small" v-model="resource.isChecked" @on-change="handleCheckOnlyResource"></Checkbox>
            </i-col>
            <i-col span="3">{{resource.resourceCode}}</i-col>
            <i-col span="2">{{resource.stationName}}</i-col>
            <i-col span="3">{{resource.assetName}}</i-col>
            <i-col span="1">{{resource.stationLevelName}}</i-col>
            <i-col span="2">
              {{resource.resourcetypeName}}
              <template v-if="resource.duration>1">
                <br/>
                ({{resource.duration}} 秒 | {{ resource.tduration / resource.duration }}次/天)
              </template>
              <template v-if="resource.side>1">
                ({{resource.side}} 面)
              </template>
            </i-col>
            <i-col span="2" class="text-center">{{resource.floor}}</i-col>
            <i-col span="2" class="text-center">{{resource.exit===''?'-':resource.exit}}</i-col>
            <i-col span="2">{{resource.positionName}}</i-col>
            <i-col span="3">{{formatMoney(resource.useprice)}}</i-col>
            <i-col span="2">
                <a @click="handleReplaceResource(resource)">替换</a>
                <a v-if="isManage" class="m-l-5" @click="handleChangeSchedule(resource)">档期</a>
                <a v-if="isManage && resource.bufferEndDate>resource.endDate" class="m-l-5" @click="handleChangeBufferEndDate(resource)">施工缓冲期</a>
                <a class="m-l-5 delete" v-if="!customskuId" @click="handleDeleteShow(resource)">删除</a>
            </i-col>
        </Row>
        <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" :current="query.pageNumber" show-total show-elevator @on-change="handlePageNumberChange"></Page>
        </div>

        <Modal v-model="showReplace" width="1000" title="资源替换" :footer-hide="true">
            <replaceMetro  :params="toReplace"  v-if="showReplace" @on-update="handleReplaced"></replaceMetro>
        </Modal>

        <Modal v-model="showChangeSchedule" width="400" title="资源档期变更" :footer-hide="true">
            <editOrderItemSchedule :params="params" :chooseOrderItemId="chooseOrderItemId" @on-update-order-item="changeSchduleSuccess"/>
        </Modal>

        <Modal v-model="showChangeBufferEndDate" width="400" title="变更施工缓冲期" :footer-hide="true">
            <editOrderItemBufferDate ref="editOrderItemBufferDate" @on-update-order-item="changeSchduleSuccess"/>
        </Modal>

        <!-- 弹窗显示点位的站点分布情况 -->
        <SvgStation ref="svgStation" :stationSvgModal.sync="showStation" :orderId="params.orderId" :stationId="currentStation.stationId"
          :customskuId="customskuId" :searchSchedules="[{startDate: params.startDate, endDate: params.endDate}]" :svgShowType="3"
          showStationPortray
          @svg-finished="svgOrThreeFinished" @handleChange3dMode="handleBeginRevice3d"></SvgStation>

        <ThreeStation ref="threeStation" @three-finished="svgOrThreeFinished" @handleChangeSvgMode="handleStationShow" />
    </div>
</template>

<script>
import replaceMetro from '../common/ReplaceMetro'
import editOrderItemSchedule from '../common/EditOrderItemSchedule'
import editOrderItemBufferDate from '../common/EditOrderItemBufferDate'

import SvgStation from '@/components/svg/SvgStation'
import ThreeStation from '@/components/svg/ThreeStation'
import { toMoney } from '@/utils/wnumb_own'

import { getStationLevelList, getProductDetailPageByOrder, getResourceCountByOrderId } from '@/api/order/productdetail'
import { deleteOrderItem } from '@/api/order/orderitem'

export default {
  props: {
    params: Object,
    customskuId: {
      type: Number,
      default: null
    }
  },
  components: {
    replaceMetro, editOrderItemSchedule, SvgStation, ThreeStation, editOrderItemBufferDate
  },
  data () {
    return {
      isManage: this.$store.state.order.isManage,
      query: { pageNumber: 1, pageSize: 10, keyword: '' },
      resourceList: [],
      total: 0,
      levelCount: [],
      stationCount: [],
      selection: [],
      showReplace: false,
      toReplace: {},
      showStation: false,
      currentStation: {
        stationId: 0
      },
      currentLevel: null,
      checkAllResource: false,

      showChangeSchedule: false, // 档期变更
      chooseOrderItemId: 0,

      showChangeBufferEndDate: false, // 施工缓冲期
      changeBufferBean: {}
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    computedRowClassName (row, index) {
      if (row.conflict) {
        return 'table-conflict-row'
      } else if (index % 2 === 0) {
        return 'table-row-1'
      } else {
        return 'table-row-2'
      }
    },
    initPageData () {
      this.initPurchaseList()
      this.getStationCount()
      this.getLevelCount()
    },
    initPurchaseList (level) {
      this.query.orderId = this.params.orderId
      this.query.productId = this.params.productId
      this.query.startDate = this.params.startDate
      this.query.endDate = this.params.endDate
      this.query.priceType = this.params.priceType
      this.query.customskuId = this.customskuId || undefined
      this.query.stationLevel = level || this.currentLevel

      getProductDetailPageByOrder(this.query).then(res => {
        this.total = res.totalRow
        this.resourceList = res.list.map(x => {
          x.isChecked = false
          return x
        })
      })
    },
    getLevelCount () {
      const levelQuery = {
        orderId: this.params.orderId,
        assetId: this.params.assetId,
        customskuId: this.customskuId || undefined,
        endDate: this.params.endDate,
        priceType: this.params.priceType,
        productId: this.params.productId,
        startDate: this.params.startDate
      }

      const levelCount = new Map()
      getStationLevelList(levelQuery).then(res => {
        res.forEach(level => {
          level.stationLevelBeanList.forEach(sl => {
            const count = levelCount.get(sl.levelName) || { levleName: '', quantity: 0 }
            count.quantity += sl.quantity
            levelCount.set(sl.levelName, Object.assign({}, sl, count))
          })
        })
        this.levelCount = [...levelCount.values()]
      })
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.initPurchaseList()
    },
    handleSearchLevel (param) {
      if (this.currentLevel === param.level) {
        this.currentLevel = undefined
      } else {
        this.currentLevel = param.level
      }
      this.query.pageNumber = 1
      this.initPurchaseList(this.currentLevel)
    },
    getStationCount () {
      const stationQuery = {
        orderId: this.params.orderId,
        productId: this.params.productId,
        customskuId: this.customskuId || undefined,
        schedules: JSON.stringify([{ startDate: this.params.startDate, endDate: this.params.endDate }]),
        priceType: this.params.priceType
      }
      getResourceCountByOrderId(stationQuery).then(res => {
        this.$emit('on-update-map', res)
        this.stationCount = []
        const assetMap = [] // 区分资产后的站点信息
        let assetStations = null

        res.forEach(resource => {
          assetStations = assetMap.find(x => x.key === resource.assetId)
          if (assetStations) { // 如果已经存在，那么给值添加资源
            assetStations = assetStations.values.push(Object.assign({}, resource))
          } else {
            assetMap.push({
              key: resource.assetId,
              values: [Object.assign({}, resource)]
            })
          }
          this.stationCount = assetMap
        })
      })
    },
    handleStationShow (station) {
      if (station.stationId) {
        this.currentStation = station
      }
      this.showStation = true
    },
    svgOrThreeFinished () {
      if (this.showStation) {
        this.$refs.svgStation.initShowBrand()
      }

      const schedule = JSON.stringify([{ startDate: this.params.startDate, endDate: this.params.endDate }])
      const resourceSearchData = {
        orderId: this.params.orderId,
        stationIds: this.currentStation.stationId,
        schedules: schedule,
        productId: this.params.productId,
        customskuId: this.customskuId,
        pageSize: 200,
        pageNumber: 1
      }

      getProductDetailPageByOrder(resourceSearchData).then(res => {
        if (this.showStation) {
          this.$refs.svgStation.tagResource(res.list)
          this.$refs.svgStation.choseSpinShow()
        } else {
          this.$refs.threeStation.tagResource(res.list)
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.currentLevel = null
      this.initPageData()
    },
    handleDeleteShow (resource) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '点击确定后资源将会从您订单里删除，请谨慎操作',
        onOk: () => {
          this.handleDelete(resource)
        }
      })
    },
    handleDelete (resource) {
      const data = {
        orderId: this.params.orderId,
        orderitemIds: resource ? JSON.stringify([resource.orderitemId]) : JSON.stringify(this.selection.map(s => s.orderitemId))
      }

      deleteOrderItem(data).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '删除成功' })
          this.$emit('on-update-order', this.params.categoryId)

          if (!resource) { this.selection = [] }
        }
      })
    },
    handleReplaceResource (resource) {
      this.toReplace = Object.assign({}, resource, { orderId: this.params.orderId, categoryId: this.params.categoryId })
      this.showReplace = true
    },
    handleReplaced () {
      this.showReplace = false
      this.$emit('on-update-order', this.params.categoryId)
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handlCheckAllResource (val) {
      this.selection = []
      this.resourceList.forEach(element => {
        element.isChecked = val
        if (val) {
          this.selection.push(element)
        }
      })
    },
    handleCheckOnlyResource () {
      this.selection = []
      this.resourceList.forEach(element => {
        if (element.isChecked) {
          this.selection.push(element)
        }
      })
    },
    handleChangeSchedule (resource) {
      this.chooseOrderItemId = resource.orderitemId
      this.showChangeSchedule = true
    },
    changeSchduleSuccess () {
      this.showChangeSchedule = false
      this.showChangeBufferEndDate = false
      this.$emit('on-update-order', this.params.categoryId)
    },
    handleChangeBufferEndDate (resource) {
      this.changeBufferBean = {
        orderId: this.params.orderId,
        orderitemId: resource.orderitemId,
        endDate: resource.endDate,
        bufferEndDate: resource.bufferEndDate
      }
      this.$refs.editOrderItemBufferDate.initChangeSchedule(this.changeBufferBean)
      this.showChangeBufferEndDate = true
    },
    handleBeginRevice3d (stationId) {
      this.$refs.threeStation.initData(stationId)
    }
  },
  watch: {
    params (val) {
      this.query.pageNumber = 1
      this.checkAllResource = false
      this.initPageData()
    }
  }
}
</script>
